/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AccessPermission } from '../model/models';
import { DocumentCreateHandle } from '../model/models';
import { FileChunkIdentifier } from '../model/models';
import { FileIdentifier } from '../model/models';
import { FileInfo } from '../model/models';
import { FileUpdateContent } from '../model/models';
import { FolderDefinition } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateDocumentRequestParams {
    documentCreateHandle: DocumentCreateHandle;
    xAuthToken?: string;
}

export interface CreateFolderRequestParams {
    folderDefinition: FolderDefinition;
    xAuthToken?: string;
}

export interface DeleteFileRequestParams {
    /** Id of file to get */
    fileid: string;
    /** Format in which to return this file */
    format?: string;
    /** Token passed via query */
    advTkGen?: string;
    /** The realm for which to obtain the file */
    realm?: string;
    xAuthToken?: string;
}

export interface GetFileRequestParams {
    /** Id of file to get */
    fileid: string;
    /** Format in which to return this file */
    format?: string;
    /** Token passed via query */
    advTkGen?: string;
    /** The realm for which to obtain the file */
    realm?: string;
    xAuthToken?: string;
}

export interface SetFilePermissionsRequestParams {
    /** Id of file to get */
    fileid: string;
    accessPermission: Array<AccessPermission>;
    xAuthToken?: string;
}

export interface UpdateFileRequestParams {
    /** Id of file to get */
    fileid: string;
    fileUpdateContent: FileUpdateContent;
    /** Format in which to return this file */
    format?: string;
    /** Token passed via query */
    advTkGen?: string;
    /** The realm for which to obtain the file */
    realm?: string;
    xAuthToken?: string;
}

export interface UploadFileRequestParams {
    xAuthToken?: string;
    chunkbyteoffset?: number;
    chunkindex?: number;
    duplicateBehavior?: string;
    file?: Blob;
    folder?: string;
    nodeid?: string;
    patchId?: string;
    patchLink?: string;
    path?: string;
    _public?: boolean;
    stateid?: string;
    threadid?: string;
    totalchunkcount?: number;
    totalfilesize?: number;
    uploadIdentifier?: string;
}


@Injectable({
  providedIn: 'root'
})
export class FilesService {

    protected basePath = 'https://api.silberfluss.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new (dynamic) document
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocument(requestParameters: CreateDocumentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FileInfo>;
    public createDocument(requestParameters: CreateDocumentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FileInfo>>;
    public createDocument(requestParameters: CreateDocumentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FileInfo>>;
    public createDocument(requestParameters: CreateDocumentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const documentCreateHandle = requestParameters.documentCreateHandle;
        if (documentCreateHandle === null || documentCreateHandle === undefined) {
            throw new Error('Required parameter documentCreateHandle was null or undefined when calling createDocument.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FileInfo>(`${this.configuration.basePath}/document/new`,
            documentCreateHandle,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new folder
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFolder(requestParameters: CreateFolderRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FileInfo>;
    public createFolder(requestParameters: CreateFolderRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FileInfo>>;
    public createFolder(requestParameters: CreateFolderRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FileInfo>>;
    public createFolder(requestParameters: CreateFolderRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const folderDefinition = requestParameters.folderDefinition;
        if (folderDefinition === null || folderDefinition === undefined) {
            throw new Error('Required parameter folderDefinition was null or undefined when calling createFolder.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FileInfo>(`${this.configuration.basePath}/folder/new`,
            folderDefinition,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete file by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFile(requestParameters: DeleteFileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteFile(requestParameters: DeleteFileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteFile(requestParameters: DeleteFileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteFile(requestParameters: DeleteFileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const fileid = requestParameters.fileid;
        if (fileid === null || fileid === undefined) {
            throw new Error('Required parameter fileid was null or undefined when calling deleteFile.');
        }
        const format = requestParameters.format;
        const advTkGen = requestParameters.advTkGen;
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (format !== undefined && format !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>format, 'format');
        }
        if (advTkGen !== undefined && advTkGen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>advTkGen, 'adv_tk_gen');
        }
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets file by id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFile(requestParameters: GetFileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getFile(requestParameters: GetFileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getFile(requestParameters: GetFileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getFile(requestParameters: GetFileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        const fileid = requestParameters.fileid;
        if (fileid === null || fileid === undefined) {
            throw new Error('Required parameter fileid was null or undefined when calling getFile.');
        }
        const format = requestParameters.format;
        const advTkGen = requestParameters.advTkGen;
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (format !== undefined && format !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>format, 'format');
        }
        if (advTkGen !== undefined && advTkGen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>advTkGen, 'adv_tk_gen');
        }
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileid))}`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets access permissions for this file
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setFilePermissions(requestParameters: SetFilePermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AccessPermission>>;
    public setFilePermissions(requestParameters: SetFilePermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AccessPermission>>>;
    public setFilePermissions(requestParameters: SetFilePermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AccessPermission>>>;
    public setFilePermissions(requestParameters: SetFilePermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fileid = requestParameters.fileid;
        if (fileid === null || fileid === undefined) {
            throw new Error('Required parameter fileid was null or undefined when calling setFilePermissions.');
        }
        const accessPermission = requestParameters.accessPermission;
        if (accessPermission === null || accessPermission === undefined) {
            throw new Error('Required parameter accessPermission was null or undefined when calling setFilePermissions.');
        }
        const xAuthToken = requestParameters.xAuthToken;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<AccessPermission>>(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileid))}/permissions`,
            accessPermission,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update this file (only possible with JSON-Formats and folders)
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFile(requestParameters: UpdateFileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FileInfo>;
    public updateFile(requestParameters: UpdateFileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FileInfo>>;
    public updateFile(requestParameters: UpdateFileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FileInfo>>;
    public updateFile(requestParameters: UpdateFileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fileid = requestParameters.fileid;
        if (fileid === null || fileid === undefined) {
            throw new Error('Required parameter fileid was null or undefined when calling updateFile.');
        }
        const fileUpdateContent = requestParameters.fileUpdateContent;
        if (fileUpdateContent === null || fileUpdateContent === undefined) {
            throw new Error('Required parameter fileUpdateContent was null or undefined when calling updateFile.');
        }
        const format = requestParameters.format;
        const advTkGen = requestParameters.advTkGen;
        const realm = requestParameters.realm;
        const xAuthToken = requestParameters.xAuthToken;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (format !== undefined && format !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>format, 'format');
        }
        if (advTkGen !== undefined && advTkGen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>advTkGen, 'adv_tk_gen');
        }
        if (realm !== undefined && realm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>realm, 'realm');
        }

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<FileInfo>(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileid))}`,
            fileUpdateContent,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload file
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFile(requestParameters: UploadFileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FileIdentifier | FileChunkIdentifier>;
    public uploadFile(requestParameters: UploadFileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FileIdentifier | FileChunkIdentifier>>;
    public uploadFile(requestParameters: UploadFileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FileIdentifier | FileChunkIdentifier>>;
    public uploadFile(requestParameters: UploadFileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xAuthToken = requestParameters.xAuthToken;
        const chunkbyteoffset = requestParameters.chunkbyteoffset;
        const chunkindex = requestParameters.chunkindex;
        const duplicateBehavior = requestParameters.duplicateBehavior;
        const file = requestParameters.file;
        const folder = requestParameters.folder;
        const nodeid = requestParameters.nodeid;
        const patchId = requestParameters.patchId;
        const patchLink = requestParameters.patchLink;
        const path = requestParameters.path;
        const _public = requestParameters._public;
        const stateid = requestParameters.stateid;
        const threadid = requestParameters.threadid;
        const totalchunkcount = requestParameters.totalchunkcount;
        const totalfilesize = requestParameters.totalfilesize;
        const uploadIdentifier = requestParameters.uploadIdentifier;

        let headers = this.defaultHeaders;
        if (xAuthToken !== undefined && xAuthToken !== null) {
            headers = headers.set('X-Auth-Token', String(xAuthToken));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (chunkbyteoffset !== undefined) {
            formParams = formParams.append('chunkbyteoffset', <any>chunkbyteoffset) as any || formParams;
        }
        if (chunkindex !== undefined) {
            formParams = formParams.append('chunkindex', <any>chunkindex) as any || formParams;
        }
        if (duplicateBehavior !== undefined) {
            formParams = formParams.append('duplicate_behavior', <any>duplicateBehavior) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (folder !== undefined) {
            formParams = formParams.append('folder', <any>folder) as any || formParams;
        }
        if (nodeid !== undefined) {
            formParams = formParams.append('nodeid', <any>nodeid) as any || formParams;
        }
        if (patchId !== undefined) {
            formParams = formParams.append('patch_id', <any>patchId) as any || formParams;
        }
        if (patchLink !== undefined) {
            formParams = formParams.append('patch_link', <any>patchLink) as any || formParams;
        }
        if (path !== undefined) {
            formParams = formParams.append('path', <any>path) as any || formParams;
        }
        if (_public !== undefined) {
            formParams = formParams.append('public', <any>_public) as any || formParams;
        }
        if (stateid !== undefined) {
            formParams = formParams.append('stateid', <any>stateid) as any || formParams;
        }
        if (threadid !== undefined) {
            formParams = formParams.append('threadid', <any>threadid) as any || formParams;
        }
        if (totalchunkcount !== undefined) {
            formParams = formParams.append('totalchunkcount', <any>totalchunkcount) as any || formParams;
        }
        if (totalfilesize !== undefined) {
            formParams = formParams.append('totalfilesize', <any>totalfilesize) as any || formParams;
        }
        if (uploadIdentifier !== undefined) {
            formParams = formParams.append('upload_identifier', <any>uploadIdentifier) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FileIdentifier | FileChunkIdentifier>(`${this.configuration.basePath}/file/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
